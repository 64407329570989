import { iconSize } from '../Start';
import FadeIn from 'react-fade-in/lib/FadeIn';

const buttonBackground = window.mentoCustomPluginBackground ? window.mentoCustomPluginBackground : '#223C8E';
const imageBackground = window.mentoCustomPluginImage ? window.mentoCustomPluginImage : 'https://gyrstiqzamltthbjartl.supabase.co/storage/v1/object/public/website/widget-icon.png';

export const PluginButton = ({ click, active }) => {
  return <div onClick={click} style={{ backgroundColor: buttonBackground, width: `${iconSize}px`, height: `${iconSize}px`, borderRadius: '50px' }}>
  <FadeIn delay={50} visible={!active}>
    <img src={imageBackground} alt="Widget Icon" style={{
      width: `${iconSize}px`,
      height: `${iconSize}px`,
      borderRadius: '50px',
      objectFit: 'cover',
      cursor: 'pointer'
    }} />
  </FadeIn>
  <FadeIn delay={50} visible={active}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', position: 'relative', top: '-50px' }}>
      <span style={{ color: 'white', textAlign: 'center', cursor: 'pointer' }}>Stäng</span>
    </div>
  </FadeIn>
</div>;
}