export const PRIMARY_COLOR = "#1976D2";
export const TEXT_GRAY = "#737373";
export const BOX_GRAY = "#E0E0E0";

export const HAPPY_COLOR = "#32CD32";
export const ANGRY_COLOR = "#FF4500";
export const SAD_COLOR = "#6495ED";
export const THOUGHTFUL_COLOR = "#9370DB";
export const UNKNOWN_COLOR = "#708090";

export const feelingsItems = [
  { label: "Arg", value: "ANGRY" },
  { label: "Ledsen", value: "SAD" },
  { label: "Glad", value: "HAPPY" },
  { label: "Fundersam", value: "THOUGHTFUL" },
  { label: "Osäker/Vill inte ange", value: "UNKNOWN" },
];

export const getFeelingLabelFromValue = (value) => {
  return feelingsItems.find((f) => f.value === value).label;
};

export const getFeelingColor = (feeling) => {
  let color;
  switch (feeling) {
    case "HAPPY":
      color = HAPPY_COLOR;
      break;
    case "ANGRY":
      color = ANGRY_COLOR;
      break;
    case "SAD":
      color = SAD_COLOR;
      break;
    case "THOUGHTFUL":
      color = THOUGHTFUL_COLOR;
      break;
    case "UNKNOWN":
      color = UNKNOWN_COLOR;
      break;
    default:
      color = PRIMARY_COLOR;
  }
  return color;
};
