import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { getLocalStorageItem } from "../utils/localstorage";
import { AnswerQuestion } from "./AnswerQuestion";
import { FeelingResults } from "./FeelingResults";
import { MoreQuestions } from "./MoreQuestions";
import { Results } from "./Results";

export const MainContentBoxExpanded = ({
  question,
  user,
  panelId,
  refreshQuestion,
  handleCloseClick,
  handleNewActiveQuestion,
  toggleWindowSize,
}) => {
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  // Filter on an answer option
  const [filterOnAnswerOption, setFilterOnAnswerOption] = useState(null);

  useEffect(() => {
    const mostAnsweredAnswerOption = question.answer_options.sort(
      (a, b) => (b.answers?.length ?? 0) - (a.answers?.length ?? 0)
    )[0];
    setFilterOnAnswerOption(mostAnsweredAnswerOption);
  }, [question]);

  const updateSelectedAnswerOptionFromLocalStorage = () => {
    const answerOption = getLocalStorageItem(question.id);
    setSelectedAnswerOption(answerOption ?? null);
  };

  const updateSelectedAnswerOption = () => {
    const selectedAnswer = question.answers.find(
      (a) => a?.external_user_id === user?.id
    );
    if (selectedAnswer) {
      setSelectedAnswerOption(selectedAnswer.answer_option_id);
    } else {
      setSelectedAnswerOption(null);
    }
  };

  useEffect(() => {
    if (user && question) {
      updateSelectedAnswerOption();
    } else {
      updateSelectedAnswerOptionFromLocalStorage();
    }
  }, [question, user]);

  return (
    <div style={{ position: "relative", paddingBottom: "8px" }}>
      <div style={{ position: "absolute", top: "0", right: "8px" }}>
        <div
          onClick={() => {
            handleCloseClick();
          }}
          style={{ cursor: "pointer", height: "12px", width: "12px" }}
        >
          <MdOutlineClose />
        </div>
      </div>
      <div style={{ marginBottom: "8px", paddingTop: "12px" }}>
        <span style={{ fontSize: "medium", fontWeight: "bold" }}>
          {question.title}
        </span>
        <p style={{ fontSize: "small" }}>{question.description}</p>
      </div>
      {selectedAnswerOption ? (
        <div>
          <Results
            question={question}
            answerOptionId={selectedAnswerOption}
            filterOnAnswerOptionId={
              filterOnAnswerOption ? filterOnAnswerOption.id : null
            }
            onAnswerOptionClicked={(aoId) => {
              if (filterOnAnswerOption && filterOnAnswerOption.id === aoId) {
                setFilterOnAnswerOption(null);
              } else {
                const answerOption = question.answer_options.find(
                  (ao) => ao.id === aoId
                );
                setFilterOnAnswerOption(answerOption);
              }
            }}
          ></Results>
          <FeelingResults
            question={question}
            filterOnAnswerOption={filterOnAnswerOption}
          ></FeelingResults>
          <MoreQuestions
            handleNewActiveQuestion={handleNewActiveQuestion}
            question={question}
            externalUserId={user ? user?.id : undefined}
            panelId={panelId ? panelId : undefined}
          ></MoreQuestions>
        </div>
      ) : (
        <AnswerQuestion
          user={user}
          onClick={(ao) => setSelectedAnswerOption(ao)}
          question={question}
          handleAnswer={refreshQuestion}
        ></AnswerQuestion>
      )}
      <a
        href="https://mento.social/"
        target="_blank"
        style={{ color: "gray", fontSize: "smaller" }}
      >
        Powered by mento.social
      </a>
    </div>
  );
};
