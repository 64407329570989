import { useEffect, useState } from "react";
import { getLocalStorageItem } from "../utils/localstorage";
import { AnswerQuestion } from "./AnswerQuestion";
import InsightHook from "./InsightHook";
import { Results } from "./Results";

export const MainContentBox = ({
  question,
  user,
  refreshQuestion,
  toggleWindowSize,
  webpageId,
}) => {
  // Todo: see if user has answered question

  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);

  const updateSelectedAnswerOptionFromLocalStorage = () => {
    const answerOption = getLocalStorageItem(question.id);
    setSelectedAnswerOption(answerOption ?? null);
  };

  const updateSelectedAnswerOption = () => {
    const selectedAnswer = question.answers.find(
      (a) => a?.external_user_id === user?.id
    );
    if (selectedAnswer) {
      setSelectedAnswerOption(selectedAnswer.answer_option_id);
    } else {
      setSelectedAnswerOption(null);
    }
  };

  useEffect(() => {
    if (user && question) {
      updateSelectedAnswerOption();
    } else {
      updateSelectedAnswerOptionFromLocalStorage();
    }
  }, [question, user]);

  return (
    <div>
      {/* Todo: Add cross */}
      <div style={{ marginBottom: "8px", marginTop: "8px" }}>
        <span style={{ fontSize: "medium", fontWeight: "bold" }}>
          {question.title}
        </span>
        <p style={{ fontSize: "small" }}>{question.description}</p>
      </div>
      {selectedAnswerOption ? (
        <div>
          <Results
            question={question}
            answerOptionId={selectedAnswerOption}
          ></Results>
          <InsightHook
            question={question}
            onExpandClick={() => toggleWindowSize(true)}
          ></InsightHook>
          {/* <PanelQuestions panelId={question.panelId} questionId={question.id}></PanelQuestions> */}
        </div>
      ) : (
        <AnswerQuestion
          user={user}
          onClick={(ao) => {
            toggleWindowSize(true);
            setSelectedAnswerOption(ao);
          }}
          question={question}
          handleAnswer={refreshQuestion}
          webpageId={webpageId}
        ></AnswerQuestion>
      )}
      <a
        href="https://mento.social/"
        target="_blank"
        style={{ color: "gray", fontSize: "smaller" }}
      >
        Powered by mento.social
      </a>
    </div>
  );
};
