import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { supabase } from "../utils/Api";
import { BOX_GRAY, PRIMARY_COLOR, TEXT_GRAY } from "../utils/theme";
import { Button } from "./ui/Button";

const pageSize = 5;

const getMoreQuestions = async (panelId, questionId, page = 1) => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize - 1;
  const query = supabase
    .from("questions")
    .select(
      `
      title,
      published_date,
      description,
      id,
      path,
      type,
      link_url,
      vote_end_date,
      panels(id, allow_anonymous_answers),
      answers!inner(id, user_id, external_user_id, feeling, answer_option_id),
      answer_options!question_id(id, answer_title, image_url, answers(id))
    `
    )
    .eq("panel_id", panelId)
    .eq("type", "QUESTION")
    .neq("id", questionId)
    .order("published_date", { ascending: false })
    .range(startIndex, endIndex);

  const { data, error } = await query;
  if (!error) {
    return data;
  } else {
    console.log(error);
    return [];
  }
};

export const MoreQuestions = ({
  question,
  externalUserId,
  panelId,
  handleNewActiveQuestion,
}) => {
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleMoreQuestions = async (extendQuestions) => {
    setLoading(true);
    const items = await getMoreQuestions(panelId, question.id, page);
    if (extendQuestions) {
      setQuestions([...questions, ...items]);
    } else {
      setQuestions(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelId && question?.id && page !== 1) {
      handleMoreQuestions(true);
    }
  }, [page]);

  useEffect(() => {
    if (panelId && question?.id) {
      setPage(1);
      handleMoreQuestions(false);
    }
  }, [question, panelId]);

  return (
    <FadeIn>
      <div style={{ marginBottom: "8px", paddingTop: "12px" }}>
        <span style={{ fontSize: "medium", fontWeight: "bold" }}>
          Andra har även svarat på
        </span>
      </div>
      <div style={{ minHeight: "140px" }}>
        {questions.length === 0 && !loading && (
          <span>Vi hittar tyvärr inga fler frågor</span>
        )}
        {questions.map((q) => {
          const hasAnswered = q.answers.find(
            (a) => a.external_user_id === externalUserId
          );
          return (
            <FadeIn>
              <div
                style={{
                  marginBottom: "8px",
                  padding: "8px",
                  borderRadius: 12,
                  backgroundColor: BOX_GRAY,
                  position: "relative",
                }}
              >
                <p
                  onClick={() => handleNewActiveQuestion(q)}
                  style={{
                    fontSize: "medium",
                    fontWeight: "bold",
                    maxWidth: "90%",
                    cursor: "pointer",
                    color: PRIMARY_COLOR,
                  }}
                >
                  {q.title}
                </p>
                <p
                  style={{
                    fontSize: "small",
                    color: "black",
                  }}
                >
                  {q.description}
                </p>
                {hasAnswered && (
                  <span
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "8px",
                      fontSize: "smaller",
                      color: TEXT_GRAY,
                    }}
                  >
                    (svarat)
                  </span>
                )}
              </div>
            </FadeIn>
          );
        })}

        {questions.length >= page * pageSize && (
          <Button
            onClick={() => setPage(page + 1)}
            text={"Se fler frågor"}
          ></Button>
        )}
      </div>
    </FadeIn>
  );
};
