import { useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { supabase } from "../utils/Api";
import { feelingsItems } from "../utils/feelings";
import { setLocalStorageItem } from "../utils/localstorage";
import { Button } from "./ui/Button";

export const AnswerQuestion = ({ question, user, onClick, handleAnswer }) => {
  const [answerOptionId, setAnswerOptionId] = useState();
  const [feeling, setFeeling] = useState();

  const answerQuestion = async (answerOptionId, feeling) => {
    try {
      if (user) {
        await storeAnswerFromExternalUser(answerOptionId, feeling);
      } else {
        await storeAnswerFromAnonymous(answerOptionId, feeling);
      }
      handleAnswer();
    } catch (e) {
      console.log(e);
    }
  };

  const storeAnswerFromAnonymous = async (aoID, f) => {
    const params = {
      input_answer_option_id: aoID,
      input_feeling: f ?? "UNKNOWN",
      input_question_id: question.id,
    };
    return await supabase.rpc("store_answer_from_anonymous_user", params);
  };

  const storeAnswerFromExternalUser = async (aoID, f) => {
    if (user && question) {
      const params = {
        input_answer_option_id: aoID,
        input_external_user_id: user.id,
        input_question_id: question.id,
        input_feeling: f,
      };
      return await supabase.rpc("store_answer_from_external_user_v3", params);
    }
  };

  if (!answerOptionId) {
    return (
      <FadeIn>
        {question.answer_options.map((ao) => (
          <div key={ao.id}>
            <div style={{ marginTop: "4px" }}>
              <Button
                onClick={() => setAnswerOptionId(ao.id)}
                text={ao.answer_title}
              ></Button>
            </div>
          </div>
        ))}
      </FadeIn>
    );
  } else if (answerOptionId) {
    return (
      <FadeIn>
        <p style={{ fontWeight: "bold" }}>Hur känner du inför det?</p>
        {feelingsItems.map((f) => (
          <div key={f.value}>
            <div style={{ marginTop: "4px" }}>
              <Button
                type={f.value}
                onClick={() => {
                  setFeeling(f.value);
                  answerQuestion(answerOptionId, f.value);
                  setLocalStorageItem(question.id, answerOptionId);
                  onClick(answerOptionId);
                }}
                text={f.label}
              ></Button>
            </div>
          </div>
        ))}
      </FadeIn>
    );
  }
};
