import "./App.css";
import Start from "./components/Start";

function App() {
  if (
    !window.mentoPanelId ||
    !(window.mentoOrganizationName || window.mentoOrganisationName)
  ) {
    return null;
  }

  return (
    <Start
      userId={window.mentoUserId}
      panelId={window.mentoPanelId}
      customerName={
        window.mentoOrganizationName || window.mentoOrganisationName
      }
      secretHash={window.mentoSecretHash}
    ></Start>
  );
}

export default App;
