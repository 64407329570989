import { MENTO_BG_COLOR } from "../utils/theme";

export function Footer() {
  return (
    <div
      style={{
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        bottom: "0",
        left: "0",
        marginLeft: "-12px",
        marginRight: "-12px",
        marginBottom: "-12px",
        width: "calc(100% + 24px)",
        padding: "12px",
        backgroundColor: MENTO_BG_COLOR,
      }}
    >
      <a
        href="https://mento.social/"
        target="_blank"
        style={{ color: "white", fontSize: "smaller", marginRight: "8px" }}
      >
        Powered by mento.social
      </a>{" "}
      <span style={{ color: "gray", fontSize: "smaller" }}>|</span>{" "}
      <a
        href="https://mento.social/faq"
        target="_blank"
        style={{ color: "white", fontSize: "smaller", marginLeft: "8px" }}
      >
        FAQ
      </a>
    </div>
  );
}
