import { useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { supabase } from "../utils/Api";
import { feelingsItems } from "../utils/feelings";
import { setLocalStorageItem } from "../utils/localstorage";
import {
  BUTTON_TEXT,
  getFeelingColor,
  PRIMARY_COLOR,
  TEXT_GRAY,
} from "../utils/theme";
import { Button } from "./ui/Button";

export const AnswerQuestion = ({
  question,
  user,
  onClick,
  handleAnswer,
  webpageId,
}) => {
  const [answerOptionId, setAnswerOptionId] = useState();
  const [feeling, setFeeling] = useState();

  const answerQuestion = async (answerOptionId, feeling) => {
    try {
      if (user) {
        await storeAnswerFromExternalUser(answerOptionId, feeling);
      } else {
        await storeAnswerFromAnonymous(answerOptionId, feeling);
      }
      handleAnswer();
    } catch (e) {
      console.log(e);
    }
  };

  const storeAnswerFromAnonymous = async (aoID, f) => {
    const params = {
      input_answer_option_id: aoID,
      input_feeling: f ?? "UNKNOWN",
      input_question_id: question.id,
      input_webpage_id: webpageId,
    };
    return await supabase.rpc("store_answer_from_anonymous_user_v2", params);
  };

  const storeAnswerFromExternalUser = async (aoID, f) => {
    if (user && question) {
      const params = {
        input_answer_option_id: aoID,
        input_external_user_id: user.id,
        input_question_id: question.id,
        input_feeling: f,
        input_webpage_id: webpageId,
      };
      return await supabase.rpc("store_answer_from_external_user_v4", params);
    }
  };

  if (!answerOptionId) {
    console.log(question.answer_options);
    return (
      <FadeIn>
        {question.answer_options
          .sort(
            (a, b) =>
              (a?.answer_option_order ?? 0) - (b?.answer_option_order ?? 0)
          )
          .map((ao) => (
            <div key={ao.id} style={{ marginTop: "8px" }}>
              <Button
                onClick={() => setAnswerOptionId(ao.id)}
                text={ao.answer_title}
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  backgroundColor: PRIMARY_COLOR,
                  color: BUTTON_TEXT,
                }}
              />
            </div>
          ))}
        <p className="text-xs mt-2" style={{ color: TEXT_GRAY }}>
          Ditt svar är anonymt
        </p>
      </FadeIn>
    );
  } else if (answerOptionId) {
    return (
      <FadeIn>
        <p style={{ fontSize: "medium", color: TEXT_GRAY }}>
          Hur känner du inför det?
        </p>
        {feelingsItems.map((f) => (
          <div key={f.value} style={{ marginTop: "8px" }}>
            <Button
              type={f.value}
              onClick={() => {
                setFeeling(f.value);
                answerQuestion(answerOptionId, f.value);
                setLocalStorageItem(question.id, answerOptionId);
                onClick(answerOptionId);
              }}
              text={f.label}
              style={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: getFeelingColor(f.value),
                color: BUTTON_TEXT,
              }}
            />
          </div>
        ))}
        <p className="text-xs mt-2" style={{ color: BUTTON_TEXT }}>
          Ditt svar är anonymt
        </p>
      </FadeIn>
    );
  }
};
