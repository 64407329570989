export const formatAnswerOptions = (
  answerOptions,
  answersLength,
  sort,
  hidePercentage
) => {
  const formatted = answerOptions
    .sort((a, b) =>
      sort ? (b.answers?.length ?? 0) - (a.answers?.length ?? 0) : 0
    )
    .map((ao) => ({
      percentage: hidePercentage
        ? null
        : answersLength && ao?.answers?.length
        ? calculatePercentage(answersLength, ao?.answers?.length)
        : 0,
      id: ao?.id,
      text: ao.answer_title,
      amount: hidePercentage ? null : ao?.answers?.length ?? 0,
    }));
  return formatted;
};

export const formatFeelings = (
  answers,
  sort,
  feelings,
  hidePercentage,
  answerOptionId
) => {
  try {
    let filteredAnswers = answers.filter((a) =>
      answerOptionId ? a.answer_option_id === answerOptionId : true
    );
    const getNumberOfAnswersFromFeeling = (feeling) => {
      return filteredAnswers.filter((a) => {
        const correctAnswerOption = answerOptionId
          ? a.answer_option_id === answerOptionId
          : true;
        return correctAnswerOption && a.feeling === feeling;
      }).length;
    };

    const formattedFeelings = feelings
      .map((f) => {
        const amount = getNumberOfAnswersFromFeeling(f.value);
        return {
          text: f.label,
          id: f.value,
          amount: amount,
          percentage: hidePercentage
            ? null
            : filteredAnswers.length > 0
            ? calculatePercentage(filteredAnswers.length, amount)
            : 0,
        };
      })
      .sort((a, b) => (sort ? (b.amount ?? 0) - (a.amount ?? 0) : 0));

    return formattedFeelings;
  } catch (e) {
    console.error(e);
  }
};

export const calculatePercentage = (totalAmount, amount) => {
  return Math.round((amount / totalAmount) * 100);
};
