import FadeIn from "react-fade-in/lib/FadeIn";
import { feelingsItems } from "../utils/feelings";
import { formatFeelings } from "../utils/formatAnswerOptions";
import { getFeelingColor } from "../utils/theme";

export const FeelingResults = ({ question, filterOnAnswerOption }) => {
  const items = formatFeelings(
    question.answers,
    true,
    feelingsItems,
    false,
    filterOnAnswerOption ? filterOnAnswerOption.id : null
  );

  const barStyle = {
    backgroundColor: "#E0E0E0",
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "5px",
    fontSize: "medium",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "small",
    flex: 1,
  };

  const rowStyle = {
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const valueStyle = {
    flex: 1,
    color: "black",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  return (
    <FadeIn>
      <p>
        Såhär känner alla som svarade{" "}
        {filterOnAnswerOption && filterOnAnswerOption.answer_title && (
          <span style={{ fontWeight: "bold", color: "#696969" }}>
            {filterOnAnswerOption.answer_title.length > 20
              ? `${filterOnAnswerOption.answer_title.substring(0, 20)}...`
              : filterOnAnswerOption.answer_title}
          </span>
        )}
      </p>
      <div style={barStyle}>
        {items.map((item, index) => (
          <div key={item?.id} style={rowStyle}>
            <span style={{ ...labelStyle, color: getFeelingColor(item.id) }}>
              {item.text}
            </span>
            <div
              style={{
                ...valueStyle,
              }}
            >
              {/* <span style={{ fontSize: "smaller" }}>({item.amount})</span>&nbsp; */}
              <span style={{ fontWeight: "bold" }}>{item.percentage}%</span>
            </div>
          </div>
        ))}
      </div>
    </FadeIn>
  );
};
