import { useEffect, useRef, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import "../App.css";
import {
  fetchQuestion,
  fetchUser,
  fetchUserAnswer,
} from "../helpers/primaryApiFunctions";
import { supabase } from "../utils/Api";
import { getLocalStorageItem } from "../utils/localstorage";
import { CustomCTA } from "./CustomCTA";
import { Footer } from "./Footer";
import { MainContentBox } from "./MainContentBox";
import { MainContentBoxExpanded } from "./MainContentBoxExpanded";
import { PluginButton } from "./ui/PluginButton";

export const iconSize = 75;
const originalWindowWidth = 250;

function Start({ userId, panelId, customerName, secretHash }) {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [userAnswerOptionId, setUserAnswerOptionId] = useState(null);
  const [activeWebpageId, setActiveWebpageId] = useState(null);
  const [displayBox, setDisplayBox] = useState(true);
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(true);
  const [registeringVisitLoading, setRegisteringVisitLoading] = useState(false);
  const [pluginPosition, setPluginPosition] = useState(null);
  const [windowWidth, setWindowWidth] = useState(originalWindowWidth);

  const contentRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    fetchPluginSettings();
  }, [customerName]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (userId && secretHash && customerName && panelId) {
      handleFetchUser();
    }
  }, [userId, secretHash, customerName, panelId]);

  const handleFetchUserAnswerOptionId = async (questionId, externalUserId) => {
    let aoId = null;
    if (externalUserId) {
      aoId = await fetchUserAnswer(questionId, externalUserId);
    } else {
      aoId = getLocalStorageItem(questionId);
    }
    setUserAnswerOptionId(aoId ?? null);
  };

  const handleFetchUser = async () => {
    setUserLoading(true);
    const response = await fetchUser(userId, secretHash, customerName, panelId);
    setUser(response);
    setUserLoading(false);
  };

  const handleFetchQuestion = async (url, id) => {
    setQuestionLoading(true);
    let { question, webpageId } = await fetchQuestion(url, id, panelId);

    if (url && !id && !question) {
      // Todo: If there's no question. Trigger edge function.
      const defaultQuestionQuery = await supabase.functions.invoke(
        "handle-default-question",
        {
          method: "POST",
          body: {
            url,
            panelId,
            webpageId,
            customerName,
          },
        }
      );

      if (defaultQuestionQuery.data.question) {
        question = defaultQuestionQuery.data.question;
      }
      if (defaultQuestionQuery.data.webpageId) {
        webpageId = defaultQuestionQuery.data.webpageId;
      }
    }

    if (question && question.id) {
      handleFetchUserAnswerOptionId(question.id, userId);
      setActiveQuestion(question);
      if (webpageId) {
        setActiveWebpageId(webpageId);
      }
      setQuestionLoading(false);
    }
  };

  const fetchQuestionFromCurrentActiveQuestion = () => {
    if (activeQuestion && activeQuestion.id) {
      handleFetchQuestion(null, activeQuestion.id);
    }
  };

  const fetchQuestionFromCurrentURL = () => {
    handleFetchQuestion(window.location.href);
  };

  useEffect(() => {
    fetchQuestionFromCurrentURL();

    // Listen for URL changes
    window.addEventListener("popstate", fetchQuestionFromCurrentURL);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", fetchQuestionFromCurrentURL);
    };
  }, [user]);

  useEffect(() => {
    if (userId && activeQuestion && !!activeQuestion?.id) {
      logUserEvent(userId, activeQuestion?.id);
    }
  }, [activeQuestion, userId]);

  const toggleWindowSize = (expand) => {
    if (expand) {
      if (isMobile) {
        setWindowWidth(window.innerWidth * 0.93);
      } else {
        setWindowWidth(400);
      }
    } else {
      setWindowWidth(originalWindowWidth);
    }
  };

  const logUserEvent = async (userId, questionId) => {
    if (!registeringVisitLoading) {
      setRegisteringVisitLoading(true);
      const visitQuery = await supabase.functions.invoke(
        "handle-page-visit-from-user",
        {
          method: "POST",
          body: {
            userId,
            questionId,
          },
        }
      );

      const { data, error, status } = await visitQuery;
      setRegisteringVisitLoading(false);
    }
  };

  const fetchPluginSettings = async () => {
    const orgQuery = await supabase.functions.invoke(
      "fetch-org-plugin-settings",
      {
        method: "POST",
        body: {
          customerName,
        },
      }
    );

    const { data, error, status } = await orgQuery;
    let position = "RIGHT";
    if (!!data && !!data.position) {
      position = data.position;
    }
    setPluginPosition(position);
  };

  const handleNewActiveQuestion = (q) => {
    setActiveQuestion(q);
    handleFetchUserAnswerOptionId(q.id, userId);
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0); // Scroll to the top of the element
    }
  };

  const allowAnonymous = activeQuestion?.panels?.allow_anonymous_answers;
  if (!pluginPosition) {
    return null;
  }

  if (!userLoading && !user && !allowAnonymous && !window.mentoCustomCtaUrl) {
    return null;
  }

  if (userLoading) {
    return null;
  }

  let pluginWrapperStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 2000,
    fontFamily:
      "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "small",
  };

  let pluginContentStyle = {
    position: "absolute",
    height: `auto`,
    width: `${windowWidth}px`,
    bottom: `${iconSize + 10}px`,
    left: `-${windowWidth - (iconSize + 10)}px`,
    boxSizing: "border-box",
    borderRadius: "8px",
    border: `1px solid rgb(98, 98, 98, 0.5)`,
    maxHeight: isMobile ? "70vh" : "500px",
    overflowY: "scroll",
    padding: "12px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    lineHeight: "1.4",
  };

  if (pluginPosition === "LEFT") {
    pluginWrapperStyle["left"] = "20px";
    pluginWrapperStyle["right"] = "auto";
    pluginContentStyle["left"] = "auto";
    pluginContentStyle["right"] = `-${windowWidth - (iconSize + 10)}px`;
  }

  const isExpanded = windowWidth > originalWindowWidth;
  if (
    displayBox &&
    isExpanded &&
    isMobile &&
    activeQuestion &&
    !!(!!user || allowAnonymous)
  ) {
    // Todo: Alternative display for mobile
    return (
      <FadeIn transitionDuration={100} visible={displayBox && !questionLoading}>
        <div style={{ ...pluginWrapperStyle, bottom: 0, right: 0, left: 0 }}>
          <div
            ref={contentRef}
            style={{
              ...pluginContentStyle,
              width: "100%",
              marginBottom: "8px",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <MainContentBoxExpanded
              user={user}
              userAnswerOptionId={userAnswerOptionId}
              handleCloseClick={() => setDisplayBox(false)}
              handleNewActiveQuestion={handleNewActiveQuestion}
              panelId={panelId ? panelId : undefined}
              question={activeQuestion}
              refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
              toggleWindowSize={toggleWindowSize}
              webpageId={activeWebpageId}
            ></MainContentBoxExpanded>
            <Footer />
          </div>
        </div>
      </FadeIn>
    );
  }

  return (
    <FadeIn delay={200} visible={activeQuestion}>
      {activeQuestion && (
        <div style={pluginWrapperStyle}>
          <PluginButton
            click={() => setDisplayBox(!displayBox)}
            active={displayBox}
          ></PluginButton>
          {displayBox && (
            <FadeIn>
              <div ref={contentRef} style={pluginContentStyle}>
                {!user && !allowAnonymous ? (
                  <CustomCTA question={activeQuestion}></CustomCTA>
                ) : isExpanded ? (
                  <MainContentBoxExpanded
                    user={user}
                    userAnswerOptionId={userAnswerOptionId}
                    handleCloseClick={() => setDisplayBox(false)}
                    handleNewActiveQuestion={handleNewActiveQuestion}
                    panelId={panelId ? panelId : undefined}
                    question={activeQuestion}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                    webpageId={activeWebpageId}
                  ></MainContentBoxExpanded>
                ) : (
                  <MainContentBox
                    user={user}
                    userAnswerOptionId={userAnswerOptionId}
                    question={activeQuestion}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                    webpageId={activeWebpageId}
                  ></MainContentBox>
                )}
                <Footer />
              </div>
            </FadeIn>
          )}
        </div>
      )}
    </FadeIn>
  );
}

export default Start;
