import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { supabase } from "../utils/Api";
import { calculatePercentage } from "../utils/formatAnswerOptions";
import {
  BOX_GRAY,
  getFeelingColor,
  getFeelingLabelFromValue,
  SELECTED_TEXT,
  TEXT_GRAY,
} from "../utils/theme";

const getFeelingResultFromQuesiton = async (questionId, answerOptionId) => {
  const { data, error } = await supabase.rpc(
    "get_feelings_statistics_for_question",
    {
      input_question_id: questionId,
      input_answer_option_id: answerOptionId ?? null,
    }
  );

  if (data && !error) {
    return data;
  } else {
    console.log(error);
    return [];
  }
};

export const FeelingResults = ({ question, filterOnAnswerOption }) => {
  const [items, setItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (question) {
      handleFetchFeelingResult();
    }
  }, [filterOnAnswerOption]);

  const handleFetchFeelingResult = async () => {
    setLoading(true);
    const feelingResult = await getFeelingResultFromQuesiton(
      question.id,
      filterOnAnswerOption?.id ?? null
    );
    const feelingArray = Object.keys(feelingResult[0])
      .map((key) => ({
        feeling: key.toUpperCase(),
        amount: feelingResult[0][key],
      }))
      .sort((a, b) => b.amount - a.amount);
    setItems(feelingArray);
    const totalAmount = feelingArray.reduce(
      (sum, item) => sum + item.amount,
      0
    );
    setTotalAmount(totalAmount);
    setLoading(false);
  };

  const barStyle = {
    backgroundColor: BOX_GRAY,
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "5px",
    fontSize: "medium",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "small",
    flex: 1,
  };

  const rowStyle = {
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const valueStyle = {
    flex: 1,
    color: "black",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  return (
    <FadeIn visible={!loading}>
      <p style={{ color: TEXT_GRAY, paddingTop: "12px", marginBottom: "4px" }}>
        Såhär känner alla som svarade{" "}
        {filterOnAnswerOption && filterOnAnswerOption.answer_title && (
          <span style={{ fontWeight: "bold", color: SELECTED_TEXT }}>
            {filterOnAnswerOption.answer_title.length > 20
              ? `${filterOnAnswerOption.answer_title.substring(0, 20)}...`
              : filterOnAnswerOption.answer_title}
          </span>
        )}
      </p>
      <div style={barStyle}>
        {items.map((item, index) => (
          <div key={item?.feeling} style={rowStyle}>
            <span
              style={{ ...labelStyle, color: getFeelingColor(item.feeling) }}
            >
              {getFeelingLabelFromValue(item.feeling)}
            </span>
            <div style={valueStyle}>
              <span style={{ fontWeight: "bold" }}>
                {calculatePercentage(totalAmount, item.amount)}%
              </span>
            </div>
          </div>
        ))}
      </div>
    </FadeIn>
  );
};
