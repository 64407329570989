import { useEffect, useRef, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import "../App.css";
import { supabase } from "../utils/Api";
import { CustomCTA } from "./CustomCTA";
import { MainContentBox } from "./MainContentBox";
import { MainContentBoxExpanded } from "./MainContentBoxExpanded";
import { PluginButton } from "./ui/PluginButton";

export const iconSize = 75;
const originalWindowWidth = 250;

function Start({ userId, panelId, customerName, secretHash }) {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [displayBox, setDisplayBox] = useState(true);
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(false);
  const [registeringVisitLoading, setRegisteringVisitLoading] = useState(false);
  const [pluginPosition, setPluginPosition] = useState(null);
  const [windowWidth, setWindowWidth] = useState(originalWindowWidth);

  const contentRef = useRef(null);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    fetchPluginSettings();
  }, [customerName]);

  useEffect(() => {
    if (userId && secretHash && customerName && panelId) {
      fetchUser();
    }
  }, [userId, secretHash, customerName, panelId]);

  const fetchUser = async () => {
    setUserLoading(true);
    try {
      const handleUserParams = {
        input_external_id: userId,
        input_external_id_hash: secretHash,
        input_communication_identifier: customerName,
        input_panel_id: panelId,
      };

      let handleUserRes = await supabase.rpc(
        "handle_external_user",
        handleUserParams
      );

      if (
        handleUserRes.status === 200 &&
        handleUserRes.data.length > 0 &&
        !!handleUserRes.data[0]?.id
      ) {
        setUser(handleUserRes.data[0]);
      } else {
        setUser(null);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setUserLoading(false);
    }
  };

  const fetchQuestionFromCurrentActiveQuestion = () => {
    if (activeQuestion && activeQuestion.id) {
      fetchQuestion(null, activeQuestion.id);
    }
  };

  const fetchQuestionFromCurrentURL = () => {
    fetchQuestion(window.location.href);
  };

  useEffect(() => {
    fetchQuestionFromCurrentURL();

    // Listen for URL changes
    window.addEventListener("popstate", fetchQuestionFromCurrentURL);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", fetchQuestionFromCurrentURL);
    };
  }, [user]);

  useEffect(() => {
    if (userId && activeQuestion && !!activeQuestion?.id) {
      logUserEvent(userId, activeQuestion?.id);
    }
  }, [activeQuestion, userId]);

  const toggleWindowSize = (expand) => {
    if (expand) {
      if (isMobile) {
        setWindowWidth(window.innerWidth * 0.93);
      } else {
        setWindowWidth(400);
      }
    } else {
      setWindowWidth(originalWindowWidth);
    }
  };

  const logUserEvent = async (userId, questionId) => {
    if (!registeringVisitLoading) {
      setRegisteringVisitLoading(true);
      const visitQuery = await supabase.functions.invoke(
        "handle-page-visit-from-user",
        {
          method: "POST",
          body: {
            userId,
            questionId,
          },
        }
      );

      const { data, error, status } = await visitQuery;
      setRegisteringVisitLoading(false);
    }
  };

  const fetchPluginSettings = async () => {
    const orgQuery = await supabase.functions.invoke(
      "fetch-org-plugin-settings",
      {
        method: "POST",
        body: {
          customerName,
        },
      }
    );

    const { data, error, status } = await orgQuery;
    let position = "RIGHT";
    if (!!data && !!data.position) {
      position = data.position;
    }
    setPluginPosition(position);
  };

  const fetchQuestion = async (url, id) => {
    try {
      const query = supabase.from("questions").select(
        `
            title,
            published_date,
            description,
            id,
            path,
            link_url,
            vote_end_date,
            panels(id, allow_anonymous_answers),
            answers(id, user_id, external_user_id, feeling, answer_option_id),
            answer_options!question_id(id, answer_title, image_url, answers(id))
          `
      );

      if (id) {
        query.eq("id", id);
      } else if (url) {
        const parsedUrl = new URL(url);
        const formattedUrl = parsedUrl.hostname + parsedUrl.pathname;
        query.like("link_url", `%${formattedUrl}`);
      }
      if (panelId) {
        query.eq("panel_id", panelId);
      }

      const { data, statusText, error } = await query;

      if (data && data.length > 0) {
        setActiveQuestion(data[0]);
      } else {
        console.log(statusText);
        console.log(error);
        setActiveQuestion(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNewActiveQuestion = (q) => {
    setActiveQuestion(q);
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0); // Scroll to the top of the element
    }
  };

  const allowAnonymous = activeQuestion?.panels?.allow_anonymous_answers;

  if (!pluginPosition) {
    return null;
  }

  if (!userLoading && !user && !allowAnonymous && !window.mentoCustomCtaUrl) {
    return null;
  }

  if (userLoading) {
    return null;
  }

  let pluginWrapperStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    zIndex: 2000,
    fontFamily:
      "Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
    fontSize: "small",
  };

  let pluginContentStyle = {
    position: "absolute",
    backgroundColor: "white",
    height: `auto`,
    width: `${windowWidth}px`,
    bottom: `${iconSize + 10}px`,
    left: `-${windowWidth - (iconSize + 10)}px`,
    boxSizing: "border-box",
    padding: "8px",
    boxShadow: "0px 0px 10px -5px black",
    borderRadius: "8px",
    maxHeight: isMobile ? "70vh" : "500px",
    overflowY: "scroll",
  };

  if (pluginPosition === "LEFT") {
    pluginWrapperStyle["left"] = "20px";
    pluginWrapperStyle["right"] = "auto";
    pluginContentStyle["left"] = "auto";
    pluginContentStyle["right"] = `-${windowWidth - (iconSize + 10)}px`;
  }

  const isExpanded = windowWidth > originalWindowWidth;
  if (
    displayBox &&
    isExpanded &&
    isMobile &&
    activeQuestion &&
    !!(!!user || allowAnonymous)
  ) {
    // Todo: Alternative display for mobile
    return (
      <FadeIn transitionDuration={100} visible={displayBox}>
        <div style={{ ...pluginWrapperStyle, bottom: 0, right: 0, left: 0 }}>
          <div
            ref={contentRef}
            style={{
              ...pluginContentStyle,
              width: "100%",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <MainContentBoxExpanded
              user={user}
              handleCloseClick={() => setDisplayBox(false)}
              handleNewActiveQuestion={handleNewActiveQuestion}
              panelId={panelId ? panelId : undefined}
              question={activeQuestion}
              refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
              toggleWindowSize={toggleWindowSize}
            ></MainContentBoxExpanded>
          </div>
        </div>
      </FadeIn>
    );
  }

  return (
    <FadeIn delay={200} visible={activeQuestion}>
      {activeQuestion && (
        <div style={pluginWrapperStyle}>
          <PluginButton
            click={() => setDisplayBox(!displayBox)}
            active={displayBox}
          ></PluginButton>
          {displayBox && (
            <FadeIn>
              <div ref={contentRef} style={pluginContentStyle}>
                {!user && !allowAnonymous ? (
                  <CustomCTA question={activeQuestion}></CustomCTA>
                ) : isExpanded ? (
                  <MainContentBoxExpanded
                    user={user}
                    handleCloseClick={() => setDisplayBox(false)}
                    handleNewActiveQuestion={handleNewActiveQuestion}
                    panelId={panelId ? panelId : undefined}
                    question={activeQuestion}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                  ></MainContentBoxExpanded>
                ) : (
                  <MainContentBox
                    user={user}
                    question={activeQuestion}
                    refreshQuestion={fetchQuestionFromCurrentActiveQuestion}
                    toggleWindowSize={toggleWindowSize}
                  ></MainContentBox>
                )}
              </div>
            </FadeIn>
          )}
        </div>
      )}
    </FadeIn>
  );
}

export default Start;
