import FadeIn from "react-fade-in/lib/FadeIn";
import { CiCircleInfo } from "react-icons/ci";
import { formatAnswerOptions } from "../utils/formatAnswerOptions";
import { BOX_GRAY, PRIMARY_COLOR } from "../utils/theme";

export const Results = ({
  question,
  answerOptionId,
  filterOnAnswerOptionId,
  onAnswerOptionClicked,
}) => {
  const items = formatAnswerOptions(
    question.answer_options ?? [],
    question?.answers?.length ?? 0,
    true
  );

  const barStyle = {
    backgroundColor: BOX_GRAY,
    borderRadius: "10px",
    padding: "10px",
    marginBottom: "5px",
    fontSize: "medium",
  };

  const labelStyle = {
    fontWeight: "bold",
    fontSize: "small",
    flex: 1,
  };

  const rowStyle = {
    paddingBottom: "6px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: onAnswerOptionClicked ? "pointer" : "auto",
    transition: "background-color 0.3s ease",
  };

  const valueStyle = {
    flex: 1,
    color: PRIMARY_COLOR,
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  };

  return (
    <FadeIn>
      <span style={{ fontSize: "small" }}>
        Antal svar: {question.answers.length}
      </span>
      <div style={barStyle}>
        {items.map((item, index) => (
          <div
            key={item?.id}
            style={{
              ...rowStyle,
              color: filterOnAnswerOptionId === item.id ? "#696969" : "black",
            }}
            onClick={() =>
              onAnswerOptionClicked ? onAnswerOptionClicked(item.id) : null
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ maxWidth: "80%" }}>
                <span style={labelStyle}>{item.text} </span>
              </div>
              <FadeIn
                transitionDuration={80}
                visible={filterOnAnswerOptionId === item.id}
              >
                {/* <BiAlignMiddle color="#696969" /> */}
                <span
                  style={{
                    marginLeft: "4px",
                    color: "#696969",
                    fontSize: "small",
                  }}
                >
                  (vald)
                </span>
              </FadeIn>
            </div>
            <div
              style={{
                ...valueStyle,
                color: answerOptionId === item.id ? "#1976D2" : "black",
              }}
            >
              {/* <span style={{ fontSize: "smaller" }}>({item.amount})</span>&nbsp; */}
              <span style={{ fontWeight: "bold" }}>{item.percentage}%</span>
            </div>
          </div>
        ))}
        {onAnswerOptionClicked && (
          <span
            style={{
              fontStyle: "italic",
              fontSize: "12px",
              color: "#696969",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CiCircleInfo /> Klicka på ett alternativ för att filtrera på det
          </span>
        )}
      </div>
    </FadeIn>
  );
};
