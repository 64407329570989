import { feelingsItems } from "../utils/feelings";
import { formatFeelings } from "../utils/formatAnswerOptions";
import { getFeelingColor, PRIMARY_COLOR } from "../utils/theme";

const InsightHook = ({ onExpandClick, question }) => {
  // Todo: Find the most popular answer_option_id, and add it
  const mostAnsweredAnswerOption = question.answer_options.sort(
    (a, b) => (b.answers?.length ?? 0) - (a.answers?.length ?? 0)
  )[0];
  const mainItem = formatFeelings(
    question.answers,
    true,
    feelingsItems,
    false,
    mostAnsweredAnswerOption.id
  ).filter((f) => f.id !== "UNKNOWN")[0];

  return mainItem.percentage !== 0 ? (
    <div>
      <p style={{ fontStyle: "italic" }}>
        {mainItem.percentage}% av alla som svarat "
        {mostAnsweredAnswerOption.answer_title}" kände sig{" "}
        <span
          style={{ color: getFeelingColor(mainItem.id), fontWeight: "bold" }}
        >
          "{mainItem.text}"
        </span>
        .{" "}
        <span
          onClick={onExpandClick}
          style={{ color: PRIMARY_COLOR, cursor: "pointer" }}
        >
          Se mer
        </span>
      </p>
    </div>
  ) : null;
};

export default InsightHook;
